var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-card",
        {
          style: { "margin-right": `${_vm.filterVideoWidth}px` },
          attrs: { "no-body": "" },
        },
        [
          _c(
            "div",
            { staticClass: "container-name" },
            [
              _c("b-card-text", {
                staticClass: "transcription-name",
                domProps: { textContent: _vm._s(_vm.transcriptionName) },
              }),
              _c(
                "b-button",
                {
                  staticClass: "btn-close",
                  attrs: { squared: "", id: "close" },
                  on: { click: _vm.onCloseEditor },
                },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/icons/close.svg") },
                  }),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-card",
        {
          style: { "margin-right": `${_vm.filterVideoWidth}px` },
          attrs: { "no-body": "" },
        },
        [
          _c(
            "b-row",
            [
              _c("FileShortcut", {
                on: {
                  newTranscription: function ($event) {
                    return _vm.newTranscription()
                  },
                  openTranscription: function ($event) {
                    return _vm.openTranscription()
                  },
                  importTranscription: function ($event) {
                    return _vm.importTranscription()
                  },
                  save: function ($event) {
                    return _vm.onSave()
                  },
                  export: function ($event) {
                    return _vm.onExport($event)
                  },
                  print: function ($event) {
                    return _vm.print()
                  },
                },
              }),
              _c("EditShortcut", {
                on: {
                  redo: function ($event) {
                    return _vm.redo()
                  },
                  copy: function ($event) {
                    return _vm.copy()
                  },
                  cut: function ($event) {
                    return _vm.cut()
                  },
                  paste: function ($event) {
                    return _vm.paste()
                  },
                  exportTo: function ($event) {
                    return _vm.exportTo()
                  },
                  search: function ($event) {
                    return _vm.search()
                  },
                  replace: function ($event) {
                    return _vm.replace()
                  },
                  insertLocationCursor: function ($event) {
                    return _vm.insertLocationCursor()
                  },
                  insertTextCursor: function ($event) {
                    return _vm.insertTextCursor()
                  },
                  showNumberPart: function ($event) {
                    return _vm.showNumberPart()
                  },
                  showNumberPages: function ($event) {
                    return _vm.showNumberPages()
                  },
                  properties: function ($event) {
                    return _vm.properties()
                  },
                  preferences: function ($event) {
                    return _vm.preferences()
                  },
                },
              }),
              _c("ActionShortcut", {
                on: {
                  forward: function ($event) {
                    return _vm.onForwardClick()
                  },
                  backward: function ($event) {
                    return _vm.onBackwardClick()
                  },
                  moveCursorToPlayback: function ($event) {
                    return _vm.moveCursorToPlayback()
                  },
                  playPreviousWord: function ($event) {
                    return _vm.playPreviousWord()
                  },
                  playCurrentWord: function ($event) {
                    return _vm.playCurrentWord()
                  },
                  playNextWord: function ($event) {
                    return _vm.playNextWord()
                  },
                },
              }),
              _c("FormatShortcut", {
                on: {
                  bold: function ($event) {
                    return _vm.bold()
                  },
                  italic: function ($event) {
                    return _vm.italic()
                  },
                  underline: function ($event) {
                    return _vm.underline()
                  },
                  fontFamily: function ($event) {
                    return _vm.fontFamily(_vm.fontProperties)
                  },
                  fontColor: function ($event) {
                    return _vm.fontColor()
                  },
                  upercase: function ($event) {
                    return _vm.upercase()
                  },
                  lowercase: function ($event) {
                    return _vm.lowercase()
                  },
                  startLowercase: function ($event) {
                    return _vm.startLowercase()
                  },
                  toggleBox: function ($event) {
                    return _vm.toggleBox()
                  },
                  startUppercase: function ($event) {
                    return _vm.startUppercase()
                  },
                  invertBox: function ($event) {
                    return _vm.invertBox()
                  },
                },
              }),
              _c("HelpShortcut", {
                on: {
                  about: function ($event) {
                    return _vm.about()
                  },
                  tutorial: function ($event) {
                    return _vm.tutorial()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }