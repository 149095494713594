var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "file-container" } },
    [
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            title: "Exportar como:",
            target: "file-export",
            triggers: "click",
            placement: "bottom",
            "hide-footer": "",
          },
          on: { ok: _vm.handleModalOk },
        },
        [
          _c(
            "div",
            { staticClass: "body-export" },
            [
              _c(
                "b-button",
                {
                  staticStyle: {
                    background: "none",
                    border: "none",
                    "margin-left": "20px",
                  },
                  attrs: { squared: "", id: "file-export" },
                  on: {
                    click: function ($event) {
                      return _vm.exportTranscription()
                    },
                  },
                },
                [
                  _c(
                    "b-link",
                    {
                      staticStyle: {
                        background: "none",
                        border: "none",
                        "margin-left": "60px",
                      },
                      attrs: { squared: "", variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.onWordExport()
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/icons/word.svg"),
                          height: "80px",
                          width: "80px",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "b-link",
                    {
                      staticStyle: {
                        background: "none",
                        border: "none",
                        "margin-left": "80px",
                      },
                      attrs: { squared: "", variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.onPdfExport()
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/icons/pdf.svg"),
                          height: "95px",
                          width: "95px",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }