var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "file-container" } },
    [
      _c(
        "b-button",
        {
          staticClass: "btn-video-controls",
          staticStyle: {
            background: "none",
            border: "none",
            "margin-left": "20px",
          },
          attrs: { hidden: "", squared: "", id: "file-save" },
          on: { click: _vm.saveTranscription },
        },
        [
          _c(
            "b-tooltip",
            {
              attrs: {
                target: "file-save",
                placement: "bottom",
                triggers: "hover",
              },
            },
            [_vm._v("Salvar")]
          ),
          _c("img", {
            attrs: {
              src: require("@/assets/icons/save_black_24dp.svg"),
              height: "24px",
              width: "24px",
            },
          }),
        ],
        1
      ),
      _c(
        "b-button",
        {
          staticStyle: {
            background: "none",
            border: "none",
            "margin-left": "20px",
          },
          attrs: { squared: "", id: "file-export" },
          on: {
            click: function ($event) {
              return _vm.exportTranscription()
            },
          },
        },
        [
          _c(
            "b-button",
            {
              staticClass: "btn-video-controls",
              staticStyle: {
                background: "none",
                border: "none",
                "margin-top": "-5px",
                "margin-left": "20px",
              },
              attrs: { squared: "" },
              on: {
                click: function ($event) {
                  return _vm.showModalExport()
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/icons/import_export_black_24dp.svg"),
                  height: "24px",
                  width: "24px",
                },
              }),
            ]
          ),
        ],
        1
      ),
      _c("ExportTranscription", {
        ref: "modalExport",
        on: {
          export: function ($event) {
            return _vm.exportTo($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }