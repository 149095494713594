var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "player-controls" }, [
      _c(
        "div",
        { staticClass: "video-controls" },
        [
          _vm.showVideoBtn
            ? _c(
                "b-button",
                {
                  staticClass: "btn-video-controls",
                  staticStyle: { background: "none", border: "none" },
                  attrs: { squared: "", id: "video-show" },
                  on: { click: _vm.showVideo },
                },
                [
                  _c(
                    "b-tooltip",
                    {
                      attrs: {
                        target: "video-show",
                        placement: "bottom",
                        triggers: "hover",
                      },
                    },
                    [_vm._v("Mostrar")]
                  ),
                  _vm.isVideoMedia
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/icons/player/videocam_button.svg"),
                          height: "20px",
                          width: "20px",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-button",
            {
              staticClass: "btn-video-controls",
              attrs: { squared: "", id: "play-pause" },
              on: { click: _vm.playVideo },
            },
            [
              _c(
                "b-tooltip",
                {
                  attrs: {
                    target: "play-pause",
                    placement: "bottom",
                    triggers: "hover",
                  },
                },
                [_vm._v("Iniciar")]
              ),
              !_vm.isVideoPlaying
                ? _c("img", {
                    attrs: {
                      src: require("@/assets/icons/player/play_button.svg"),
                    },
                  })
                : _vm._e(),
              _vm.isVideoPlaying
                ? _c("img", {
                    attrs: {
                      src: require("@/assets/icons/player/pause.svg"),
                      height: "20px",
                      width: "20px",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "btn-video-controls",
              attrs: { squared: "", id: "stop" },
              on: { click: _vm.stopVideo },
            },
            [
              _c(
                "b-tooltip",
                {
                  attrs: {
                    target: "stop",
                    placement: "bottom",
                    triggers: "hover",
                  },
                },
                [_vm._v("Parar")]
              ),
              _c("img", {
                attrs: {
                  src: require("@/assets/icons/player/stop_button.svg"),
                  height: "18px",
                  width: "18px",
                },
              }),
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "btn-video-controls",
              attrs: { squared: "", id: "backward" },
              on: { click: _vm.backwardVideo },
            },
            [
              _c(
                "b-tooltip",
                {
                  attrs: {
                    target: "backward",
                    placement: "bottom",
                    triggers: "hover",
                  },
                },
                [_vm._v("Voltar")]
              ),
              _c("img", {
                attrs: {
                  src: require("@/assets/icons/player/retrocede_button.svg"),
                },
              }),
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "btn-video-controls",
              attrs: { squared: "", id: "forward" },
              on: { click: _vm.forwardVideo },
            },
            [
              _c(
                "b-tooltip",
                {
                  attrs: {
                    target: "forward",
                    placement: "bottom",
                    triggers: "hover",
                  },
                },
                [_vm._v("Avançar")]
              ),
              _c("img", {
                attrs: {
                  src: require("@/assets/icons/player/fast-forward.svg"),
                },
              }),
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "btn-video-controls",
              attrs: { squared: "", id: "volume" },
            },
            [
              _c(
                "b-tooltip",
                {
                  attrs: {
                    target: "volume",
                    placement: "bottom",
                    triggers: "hover",
                  },
                },
                [_vm._v("Volume")]
              ),
              _c("img", {
                attrs: {
                  src: require("@/assets/icons/player/volume_button.svg"),
                },
              }),
            ],
            1
          ),
          _c("b-form-input", {
            staticClass: "range-volume",
            staticStyle: { "margin-right": "20px" },
            attrs: { type: "range", min: "0", max: "100" },
            model: {
              value: _vm.volumeValue,
              callback: function ($$v) {
                _vm.volumeValue = $$v
              },
              expression: "volumeValue",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }