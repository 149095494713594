var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "edit-container" }, [
      _c(
        "div",
        { staticClass: "file-container" },
        [
          _c(
            "b-nav-item-dropdown",
            {
              staticClass: "nav-dropdown",
              attrs: {
                id: "dropdown-form",
                offset: "25",
                text: "Editar",
                "no-caret": "",
              },
            },
            [
              _c(
                "b-dropdown-form",
                [
                  _c("b-dropdown-item"),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { id: "dropdown-form", href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.undo()
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "dropdown-item" }, [
                        _vm._v("Desfazer "),
                        _c(
                          "span",
                          { staticStyle: { "margin-left": "220px" } },
                          [_vm._v("Ctrl + Z")]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.redo()
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "dropdown-item" }, [
                        _vm._v("Refazer "),
                        _c(
                          "span",
                          { staticStyle: { "margin-left": "229px" } },
                          [_vm._v("Ctrl + Shift + Z")]
                        ),
                      ]),
                    ]
                  ),
                  _c("b-dropdown-divider"),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.copy()
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "dropdown-item" }, [
                        _vm._v("Copiar"),
                        _c(
                          "span",
                          { staticStyle: { "margin-left": "237px" } },
                          [_vm._v("Ctrl + C")]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.cut()
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "dropdown-item" }, [
                        _vm._v("Cortar "),
                        _c(
                          "span",
                          { staticStyle: { "margin-left": "236px" } },
                          [_vm._v("Ctrl + X")]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.paste()
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "dropdown-item" }, [
                        _vm._v("Colar "),
                        _c(
                          "span",
                          { staticStyle: { "margin-left": "243px" } },
                          [_vm._v("Ctrl + V")]
                        ),
                      ]),
                    ]
                  ),
                  _c("b-dropdown-divider"),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.exportTo()
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "dropdown-item" }, [
                        _vm._v(" Exportar para área de transferência"),
                        _c("span", { staticStyle: { "margin-left": "34px" } }, [
                          _vm._v("Ctrl + Shift + C"),
                        ]),
                      ]),
                    ]
                  ),
                  _c("b-dropdown-divider"),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.search()
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "dropdown-item" }, [
                        _vm._v("Pesquisar "),
                        _c(
                          "span",
                          { staticStyle: { "margin-left": "212px" } },
                          [_vm._v("Ctrl + F")]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.replace()
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "dropdown-item" }, [
                        _vm._v("Substituir "),
                        _c(
                          "span",
                          { staticStyle: { "margin-left": "215px" } },
                          [_vm._v("Ctrl + R")]
                        ),
                      ]),
                    ]
                  ),
                  _c("b-dropdown-divider"),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.insertLocationCursor()
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "dropdown-item" }, [
                        _vm._v("Inserir um local no cursor "),
                        _c("span", { staticStyle: { "margin-left": "10px" } }, [
                          _vm._v("Ctrl + Enter"),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.insertTextCursor()
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "dropdown-item" }, [
                        _vm._v("Inserir um texto livre no cursor "),
                        _c("span", { staticStyle: { "margin-left": "71px" } }, [
                          _vm._v("Shift + Enter"),
                        ]),
                      ]),
                    ]
                  ),
                  _c("b-dropdown-divider"),
                  _c(
                    "b-form-checkbox",
                    {
                      staticStyle: {
                        "font-family": "Poppins",
                        "font-size": "14px",
                        "font-weight": "400",
                        color: "#6278A3",
                        "line-height": "25px",
                        "margin-top": "-4px",
                        "margin-left": "25px",
                      },
                    },
                    [
                      _vm._v("Mostrar número das partes"),
                      _c("span", { staticStyle: { "margin-left": "69px" } }, [
                        _vm._v("Ctrl + L"),
                      ]),
                    ]
                  ),
                  _c(
                    "b-form-checkbox",
                    { staticStyle: { "margin-left": "25px" } },
                    [
                      _c("p", { staticClass: "dropdown-item" }, [
                        _vm._v("Mostrar número de páginas"),
                      ]),
                    ]
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.properties()
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "dropdown-item" }, [
                        _vm._v("Propriedades"),
                      ]),
                    ]
                  ),
                  _c("b-dropdown-divider"),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.preferences()
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "dropdown-item mb-2" }, [
                        _vm._v("Preferências..."),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }