var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "file-container" }, [
      _c(
        "div",
        { staticClass: "file-container" },
        [
          _c(
            "b-nav-item-dropdown",
            {
              attrs: {
                id: "dropdown-main",
                offset: "25",
                text: "Ação",
                "no-caret": "",
              },
            },
            [
              _c(
                "b-dropdown-item",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.onBackwardClick()
                    },
                  },
                },
                [
                  _c("p", { staticClass: "dropdown-item" }, [
                    _vm._v("Mover cursor para interlocutor anterior"),
                    _c("span", { staticStyle: { "margin-left": "50px" } }, [
                      _vm._v("Ctrl + seta para cima"),
                    ]),
                  ]),
                ]
              ),
              _c(
                "b-dropdown-item",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.onForwardClick()
                    },
                  },
                },
                [
                  _c("p", { staticClass: "dropdown-item" }, [
                    _vm._v("Mover para próximo interlocutor"),
                    _c("span", { staticStyle: { "margin-left": "96px" } }, [
                      _vm._v("Ctrl + seta para baixo"),
                    ]),
                  ]),
                ]
              ),
              _c(
                "b-dropdown-item",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.moveCursorToPlayback()
                    },
                  },
                },
                [
                  _c("p", { staticClass: "dropdown-item" }, [
                    _vm._v("Mover cursor para posição de reprodução"),
                    _c("span", { staticStyle: { "margin-left": "25px" } }, [
                      _vm._v("Ctrl + Alt + barra de espaços"),
                    ]),
                  ]),
                ]
              ),
              _c("b-dropdown-divider"),
              _c(
                "b-dropdown-item",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.playPreviousWord()
                    },
                  },
                },
                [
                  _c("p", { staticClass: "dropdown-item" }, [
                    _vm._v("Reproduzir palavra anterior"),
                    _c("span", { staticStyle: { "margin-left": "130px" } }, [
                      _vm._v("Ctrl + seta para esquerda"),
                    ]),
                  ]),
                ]
              ),
              _c(
                "b-dropdown-item",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.playCurrentWord()
                    },
                  },
                },
                [
                  _c("p", { staticClass: "dropdown-item" }, [
                    _vm._v("Reproduzir palavra atual"),
                    _c("span", { staticStyle: { "margin-left": "149px" } }, [
                      _vm._v("Ctrl + seta para baixo"),
                    ]),
                  ]),
                ]
              ),
              _c(
                "b-dropdown-item",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.playNextWord()
                    },
                  },
                },
                [
                  _c("p", { staticClass: "dropdown-item" }, [
                    _vm._v("Reproduzir próxima palavra"),
                    _c("span", { staticStyle: { "margin-left": "128px" } }, [
                      _vm._v("Ctrl + seta para direita"),
                    ]),
                  ]),
                ]
              ),
              _c("b-dropdown-divider"),
              _c(
                "b-nav-item-dropdown",
                {
                  staticClass: "ml-2 m-2 canais dropdown-item2",
                  attrs: {
                    id: "dropdown-secondary",
                    text: "Canais >>",
                    block: "",
                    dropright: "",
                    "no-caret": "",
                  },
                },
                [
                  _c("b-dropdown-item", [
                    _c("p", { staticClass: "dropdown-item" }, [
                      _vm._v("Canal: Locutor 1"),
                    ]),
                  ]),
                  _c("b-dropdown-item", [
                    _c("p", { staticClass: "dropdown-item" }, [
                      _vm._v("Canal: Locutor 2"),
                    ]),
                  ]),
                  _c("b-dropdown-item", [
                    _c("p", { staticClass: "dropdown-item" }, [
                      _vm._v("Canal: Locutor 3"),
                    ]),
                  ]),
                  _c("b-dropdown-item", [
                    _c("p", { staticClass: "dropdown-item" }, [
                      _vm._v("Canal: Locutor 4"),
                    ]),
                  ]),
                  _c("b-dropdown-item", [
                    _c("p", { staticClass: "dropdown-item mb-2" }, [
                      _vm._v("Todos os canais"),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }