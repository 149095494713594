var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "player-controls" }, [
      _c(
        "div",
        { staticClass: "video-controls" },
        [
          _c(
            "b-button",
            {
              staticClass: "btn-video-controls",
              staticStyle: { background: "none", border: "none" },
              attrs: { squared: "", id: "rating" },
              on: {
                click: function ($event) {
                  return _vm.onClickRating()
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/icons/action/star_button.svg"),
                  height: "20px",
                  width: "20px",
                },
              }),
            ]
          ),
          _c(
            "b-button",
            {
              staticClass: "btn-video-controls",
              staticStyle: { background: "none", border: "none" },
              attrs: { squared: "" },
              on: {
                click: function ($event) {
                  return _vm.showModalQuality()
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/icons/action/stamp_button.svg"),
                  height: "20px",
                  width: "20px",
                },
              }),
            ]
          ),
          _c("Rating", {
            ref: "modalRating",
            attrs: { transcriptionId: _vm.transcriptionId },
            on: {
              rating: function ($event) {
                return _vm.rating()
              },
            },
          }),
          _c("Chart", { ref: "modalChart", attrs: { quality: _vm.quality } }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }