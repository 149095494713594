var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "help-container" }, [
      _c(
        "div",
        { staticClass: "file-container" },
        [
          _c(
            "b-nav-item-dropdown",
            {
              staticClass: "nav-dropdown",
              attrs: { offset: "25", text: "Ajuda", "no-caret": "" },
            },
            [
              _c(
                "b-dropdown-item",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.about()
                    },
                  },
                },
                [_c("p", { staticClass: "dropdown-item" }, [_vm._v("Sobre")])]
              ),
              _c(
                "b-dropdown-item",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.tutorial()
                    },
                  },
                },
                [
                  _c("p", { staticClass: "dropdown-item mb-2" }, [
                    _vm._v("Tutorial básico"),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }