var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-container" }, [
    _c(
      "div",
      { staticClass: "file-container" },
      [
        _c(
          "b-nav-item-dropdown",
          {
            attrs: {
              size: "lg",
              split: "",
              offset: "25",
              text: "Arquivo",
              "no-caret": "",
            },
          },
          [
            _c(
              "div",
              [
                _c(
                  "b-dropdown-item",
                  {
                    attrs: { id: "new_transcription" },
                    on: {
                      click: function ($event) {
                        return _vm.newTranscription()
                      },
                    },
                  },
                  [
                    _c("p", { staticClass: "dropdown-item fontes" }, [
                      _vm._v("Nova Transcrição "),
                      _c("span", { staticStyle: { "margin-left": "52px" } }, [
                        _vm._v("Ctrl + N"),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "b-dropdown-item",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.openTranscription()
                      },
                    },
                  },
                  [
                    _c("p", { staticClass: "dropdown-item" }, [
                      _vm._v("Abrir transcrição "),
                      _c("span", { staticStyle: { "margin-left": "54px" } }, [
                        _vm._v("Ctrl + Alt + O"),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "b-dropdown-item",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.importTranscription()
                      },
                    },
                  },
                  [
                    _c("p", { staticClass: "dropdown-item" }, [
                      _vm._v("Importar transcrição"),
                    ]),
                  ]
                ),
                _c("b-dropdown-divider"),
                _c(
                  "b-dropdown-item",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.onSave()
                      },
                    },
                  },
                  [
                    _c("p", { staticClass: "dropdown-item" }, [
                      _vm._v("Salvar "),
                      _c("span", { staticStyle: { "margin-left": "127px" } }, [
                        _vm._v("Ctrl + S"),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "b-dropdown-item",
                  {
                    ref: "showModal",
                    on: {
                      click: function ($event) {
                        _vm.showModalExport(), _vm.exportTranscription()
                      },
                    },
                  },
                  [
                    _c(
                      "p",
                      { staticClass: "dropdown-item" },
                      [
                        _vm._v("Exportar "),
                        _c(
                          "span",
                          { staticStyle: { "margin-left": "113px" } },
                          [_vm._v("Ctrl + E")]
                        ),
                        _c("ExportTranscription", {
                          ref: "modalExport",
                          on: {
                            export: function ($event) {
                              return _vm.exportTo($event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c("b-dropdown-divider", { staticClass: "mb-0 pt-0" }),
                _c(
                  "b-dropdown-item",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.print()
                      },
                    },
                  },
                  [
                    _c("p", { staticClass: "dropdown-item mb-2" }, [
                      _vm._v("Imprimir "),
                      _c("span", { staticStyle: { "margin-left": "112px" } }, [
                        _vm._v("Ctrl + P"),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }