var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-sidebar",
        {
          staticClass: "sidebar",
          attrs: { id: "sidebar", right: "", shadow: "", "no-slide": "" },
          model: {
            value: _vm.showVideo,
            callback: function ($$v) {
              _vm.showVideo = $$v
            },
            expression: "showVideo",
          },
        },
        [
          _c("video", {
            ref: "player",
            staticClass: "videoPlayer",
            attrs: { preload: "auto" },
            on: {
              timeupdate: _vm.onTimeUpdate,
              play: _vm.onPlayVideo,
              pause: _vm.onPauseVideo,
            },
          }),
          _c(
            "b-button",
            { staticClass: "new-user-button", on: { click: _vm.onClickPIP } },
            [_vm._v("PIP")]
          ),
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "wave-container" },
        [
          _vm.loaded
            ? _c("av-waveform", {
                key: _vm.canvasWidth,
                ref: "wave",
                staticClass: "audio-class",
                attrs: {
                  "ref-link": "player",
                  playedLineColor: "dusty",
                  "cors-anonym": "",
                  "canv-width": _vm.canvasWidth,
                },
                on: {
                  contentType: function ($event) {
                    return _vm.contentTypeResponse($event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }