var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      !_vm.editorJS
        ? _c("MenuShortcut", {
            ref: "menuShortcut",
            attrs: {
              transcriptionName: _vm.transcriptionName,
              transcriptionId: _vm.transcriptionId,
            },
            on: {
              close: function ($event) {
                return _vm.onCloseEditor()
              },
              newTranscription: function ($event) {
                return _vm.newTranscription()
              },
              openTranscription: function ($event) {
                return _vm.openTranscription()
              },
              importTranscription: function ($event) {
                return _vm.importTranscription()
              },
              save: function ($event) {
                return _vm.onSaveShortCut()
              },
              export: function ($event) {
                return _vm.onExport($event)
              },
              print: function ($event) {
                return _vm.print()
              },
              undo: function ($event) {
                return _vm.undo()
              },
              redo: function ($event) {
                return _vm.redo()
              },
              copy: function ($event) {
                return _vm.copy()
              },
              cut: function ($event) {
                return _vm.cut()
              },
              paste: function ($event) {
                return _vm.paste()
              },
              expotTo: function ($event) {
                return _vm.expotTo()
              },
              search: function ($event) {
                return _vm.search()
              },
              replace: function ($event) {
                return _vm.replace()
              },
              insertLocationCursor: function ($event) {
                return _vm.insertLocationCursor()
              },
              insertTextCursor: function ($event) {
                return _vm.insertTextCursor()
              },
              showPartNumberCheckBox: function ($event) {
                return _vm.showPartNumberCheckBox()
              },
              showPartPagesCheckBox: function ($event) {
                return _vm.showPartPagesCheckBox()
              },
              properties: function ($event) {
                return _vm.properties()
              },
              preferences: function ($event) {
                return _vm.preferences()
              },
              forward: function ($event) {
                return _vm.onForwardClicked()
              },
              backward: function ($event) {
                return _vm.onBackwardClicked()
              },
              bold: function ($event) {
                return _vm.bold()
              },
              fontFamily: function ($event) {
                return _vm.fontFamily(_vm.fontProperties)
              },
              italic: function ($event) {
                return _vm.italic()
              },
            },
          })
        : _vm._e(),
      _c("Menu", {
        ref: "menu",
        staticClass: "section-menu",
        attrs: {
          transcriptionName: _vm.transcriptionName,
          transcriptionId: _vm.transcriptionId,
          quality: _vm.quality,
        },
        on: {
          showVideo: function ($event) {
            return _vm.onVideoShow($event)
          },
          close: function ($event) {
            return _vm.onCloseEditor()
          },
          play: function ($event) {
            return _vm.onPlayClicked()
          },
          pause: function ($event) {
            return _vm.onPauseClicked()
          },
          stop: function ($event) {
            return _vm.onStopClicked()
          },
          forward: function ($event) {
            return _vm.onForwardClicked()
          },
          backward: function ($event) {
            return _vm.onBackwardClicked()
          },
          bold: function ($event) {
            return _vm.bold()
          },
          fontFamily: function ($event) {
            return _vm.fontFamily(_vm.fontProperties)
          },
          italic: function ($event) {
            return _vm.italic()
          },
          strike: function ($event) {
            return _vm.strike()
          },
          highlight: function ($event) {
            return _vm.highlight()
          },
          bulletList: function ($event) {
            return _vm.bulletList()
          },
          orderedList: function ($event) {
            return _vm.orderedList()
          },
          undo: function ($event) {
            return _vm.undo()
          },
          redo: function ($event) {
            return _vm.redo()
          },
          save: function ($event) {
            return _vm.onSave()
          },
          export: function ($event) {
            return _vm.onExport($event)
          },
          onVolumeChanged: function ($event) {
            return _vm.onVolumeChanged($event)
          },
        },
      }),
      _c(
        "b-card",
        {
          ref: "editContainer",
          staticClass: "edit-container",
          style: {
            "max-height": `${_vm.filterSectionHeight}px`,
            "margin-right": `${_vm.filterVideoWidth}px`,
          },
          attrs: { "no-body": "" },
        },
        [
          _c(
            "div",
            { ref: "editContainer2", staticClass: "transcription-container" },
            [
              _vm.editorJS
                ? _c("EditorJs", {
                    ref: "editor",
                    on: { positionTime: _vm.onClickPosition },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "vue-html2pdf",
        {
          ref: "html2Pdf",
          attrs: {
            "show-layout": false,
            "float-layout": true,
            "enable-download": false,
            "manual-pagination": true,
            "preview-modal": true,
            pdfContentWidth: 400,
            "paginate-elements-by-height": 1400,
            "pdf-content-width": "200%",
            filename: this.transcriptionName,
            htmlToPdfOptions: this.pdfOptions,
          },
        },
        [
          _c("section", {
            ref: "pdfContent",
            attrs: { slot: "pdf-content" },
            slot: "pdf-content",
          }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "wave-tag",
          style: {
            right: `${_vm.filterVideoWidth > 0 ? "320" : "0"}px`,
            left: `${_vm.filterVideoWidth > 0 ? "200" : "200"}px`,
          },
        },
        [
          _c("Wave", {
            ref: "wave",
            staticClass: "wave",
            style: { "min-width": `${_vm.filterWaveWidth}px` },
            attrs: { mediaPath: _vm.mediaPath },
            on: {
              timeupdate: _vm.onAudioTimeUpdate,
              showVideoButton: function ($event) {
                return _vm.showVideoButton($event)
              },
              pipVideo: function ($event) {
                return _vm.pipVideo($event)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }