var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: { "ok-title": _vm.submitTitle, title: "Qualidade" },
          on: { hidden: _vm.resetModal, ok: _vm.handleModalOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function ($event) {
                          return _vm.hideModalRating()
                        },
                      },
                    },
                    [_vm._v("Fechar")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", [
            _c(
              "h4",
              {
                staticClass: "pb-3",
                staticStyle: {
                  "font-weight": "500",
                  "font-size": "1.15rem !important",
                  "font-family": "Poppins !important",
                },
              },
              [
                _vm._v(
                  "Qualidade da transcrição: " +
                    _vm._s(_vm.transcriptionQuality)
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "mt-2 quality-rating",
                staticStyle: {
                  "font-weight": "400",
                  "font-size": "14px !important",
                  "font-family": "Inter !important",
                },
              },
              [
                _vm._v(
                  "A transcrição deste arquivo foi avaliada como sendo de boa qualidade. Poucos ajustes podem ser necessários para tornar o material completo. Você pode solicitar a contratação de um corretor Retextoar para não necessitar realizar ajustes manuais."
                ),
              ]
            ),
          ]),
          _c("hr"),
          _c(
            "div",
            [
              _c("Bar", {
                attrs: {
                  "chart-options": _vm.chartOptions,
                  "chart-data": _vm.chartData,
                  "chart-id": _vm.chartId,
                  "dataset-id-key": _vm.datasetIdKey,
                  plugins: _vm.plugins,
                  "css-classes": _vm.cssClasses,
                  styles: _vm.styles,
                  width: _vm.width,
                  height: _vm.height,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }