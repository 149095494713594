var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-card",
        {
          style: { "margin-right": `${_vm.filterVideoWidth}px` },
          attrs: { "no-body": "" },
        },
        [
          _c(
            "div",
            { staticClass: "container-name" },
            [
              _c("b-card-text", {
                staticClass: "transcription-name",
                domProps: { textContent: _vm._s(_vm.transcriptionName) },
              }),
              _c(
                "b-button",
                {
                  staticClass: "btn-close",
                  attrs: { squared: "", id: "close" },
                  on: { click: _vm.onCloseEditor },
                },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/icons/close.svg") },
                  }),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-card",
        {
          style: { "margin-right": `${_vm.filterVideoWidth}px` },
          attrs: { "no-body": "" },
        },
        [
          _c(
            "b-row",
            [
              _c("File", {
                on: {
                  save: function ($event) {
                    return _vm.onSave()
                  },
                  export: function ($event) {
                    return _vm.onExport($event)
                  },
                },
              }),
              _c("div", { staticClass: "line" }),
              _c("Player", {
                ref: "player",
                on: {
                  onVolumeChanged: function ($event) {
                    return _vm.onVolumeChanged($event)
                  },
                  showVideo: function ($event) {
                    return _vm.onShowVideo($event)
                  },
                  play: function ($event) {
                    return _vm.onPlayClicked()
                  },
                  pause: function ($event) {
                    return _vm.onPauseClicked()
                  },
                  stop: function ($event) {
                    return _vm.onStopClicked()
                  },
                  forward: function ($event) {
                    return _vm.onForwardClick()
                  },
                  backward: function ($event) {
                    return _vm.onBackwardClick()
                  },
                },
              }),
              _c("div", { staticClass: "line" }),
              _vm.showFormat
                ? _c("Format", {
                    on: {
                      bold: function ($event) {
                        return _vm.bold()
                      },
                      fontFamily: function ($event) {
                        return _vm.fontFamily(_vm.fontProperties)
                      },
                      italic: function ($event) {
                        return _vm.italic()
                      },
                      strike: function ($event) {
                        return _vm.strike()
                      },
                      highlight: function ($event) {
                        return _vm.highlight()
                      },
                      bulletList: function ($event) {
                        return _vm.bulletList()
                      },
                      orderedList: function ($event) {
                        return _vm.orderedList()
                      },
                      undo: function ($event) {
                        return _vm.undo()
                      },
                      redo: function ($event) {
                        return _vm.redo()
                      },
                    },
                  })
                : _vm._e(),
              _vm.showFormat ? _c("div", { staticClass: "line" }) : _vm._e(),
              _c("Feedback", {
                attrs: {
                  transcriptionId: _vm.transcriptionId,
                  quality: _vm.quality,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }