var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            "ok-title": _vm.submitTitle,
            "cancel-title": "Cancelar",
            title: "Avaliação da transcrição",
          },
          on: { hidden: _vm.resetModal, ok: _vm.handleModalOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn btn-transparent",
                      on: {
                        click: function ($event) {
                          return _vm.hideModalRating()
                        },
                      },
                    },
                    [_vm._v(" Cancelar ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function ($event) {
                          return _vm.handleModalOk($event)
                        },
                      },
                    },
                    [_vm._v(" Enviar ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _c(
                "p",
                {
                  staticClass: "mt-2 quality-rating",
                  staticStyle: { "font-weight": "500" },
                },
                [_vm._v("Qualidade da transcrição")]
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    state: _vm.ratingState,
                    "invalid-feedback": "Avaliação necessária",
                  },
                },
                [
                  _c("b-form-rating", {
                    staticClass: "rating",
                    attrs: { inline: "" },
                    model: {
                      value: _vm.ratingValue,
                      callback: function ($$v) {
                        _vm.ratingValue = $$v
                      },
                      expression: "ratingValue",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("hr"),
          _c("b-form-textarea", {
            staticClass: "textarea-text",
            attrs: {
              rows: "3",
              "no-resize": "",
              placeholder:
                "Escreva um comentário sobre a qualidade da transcrição.",
              state: _vm.commentsState,
              "invalid-feedback": "Descrição é necessária",
            },
            model: {
              value: _vm.comments,
              callback: function ($$v) {
                _vm.comments = $$v
              },
              expression: "comments",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }