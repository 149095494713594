var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "maincontent" } }, [
    _c("div", { attrs: { id: "transcriptioncontent" } }, [
      _c("div", { staticClass: "content", style: _vm.filterSectionHeight }, [
        _c("div", {
          staticClass: "main-title",
          attrs: { id: "transcriptionname" },
        }),
        _c("div", { staticClass: "speech", attrs: { id: "transcription" } }),
      ]),
    ]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { attrs: { id: "mediacontent" } }, [
      _c("div", { attrs: { id: "audiocontrol" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }