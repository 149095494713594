var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "player-controls" }, [
      _c(
        "div",
        { staticClass: "video-controls" },
        [
          _c(
            "b-row",
            [
              _c(
                "button",
                {
                  staticClass: "btn-video-controls",
                  staticStyle: { background: "none", border: "none" },
                  attrs: { squared: "", id: "undo" },
                  on: {
                    click: function ($event) {
                      return _vm.undo()
                    },
                  },
                },
                [
                  _c(
                    "b-tooltip",
                    {
                      attrs: {
                        target: "undo",
                        placement: "bottom",
                        triggers: "hover",
                      },
                    },
                    [_vm._v("Desfazer")]
                  ),
                  _c("img", {
                    attrs: {
                      src: require("@/assets/icons/format/redo.svg"),
                      height: "28px",
                      width: "28px",
                    },
                  }),
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "btn-video-controls",
                  staticStyle: {
                    background: "none",
                    border: "none",
                    "margin-rigth": "20px",
                  },
                  attrs: { squared: "", id: "redo" },
                  on: {
                    click: function ($event) {
                      return _vm.redo()
                    },
                  },
                },
                [
                  _c(
                    "b-tooltip",
                    {
                      attrs: {
                        target: "redo",
                        placement: "bottom",
                        triggers: "hover",
                      },
                    },
                    [_vm._v("Refazer")]
                  ),
                  _c("img", {
                    attrs: {
                      src: require("@/assets/icons/format/undo.svg"),
                      height: "28px",
                      width: "28px",
                    },
                  }),
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "btn-video-controls",
                  class: { "is-active": true },
                  staticStyle: {
                    background: "none",
                    border: "none",
                    "margin-left": "30px",
                  },
                  attrs: { squared: "", id: "bold" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleBold()
                    },
                  },
                },
                [
                  _c(
                    "b-tooltip",
                    {
                      attrs: {
                        target: "bold",
                        placement: "bottom",
                        triggers: "hover",
                      },
                    },
                    [_vm._v("Negrito")]
                  ),
                  _c("img", {
                    attrs: { src: require("@/assets/icons/format/bold.svg") },
                  }),
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "btn-video-controls",
                  staticStyle: {
                    background: "none",
                    border: "none",
                    "margin-right": "5px",
                    "margin-left": "-10px",
                  },
                  attrs: { squared: "", id: "font_family" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleFontFamily()
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/icons/format/font_family.svg"),
                      height: "28px",
                      width: "28px",
                    },
                  }),
                ]
              ),
              _c("SelectFonts", {
                ref: "selectedFonts",
                on: {
                  fontFamily: function ($event) {
                    return _vm.fontFamily(_vm.fontProperties)
                  },
                },
              }),
              _c(
                "button",
                {
                  staticClass: "btn-video-controls",
                  class: { "is-active": true },
                  staticStyle: { background: "none", border: "none" },
                  attrs: { squared: "", id: "italic" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleItalic()
                    },
                  },
                },
                [
                  _c(
                    "b-tooltip",
                    {
                      attrs: {
                        target: "italic",
                        placement: "bottom",
                        triggers: "hover",
                      },
                    },
                    [_vm._v("Itálico")]
                  ),
                  _c("img", {
                    attrs: {
                      src: require("@/assets/icons/format/italic.svg"),
                      height: "28px",
                      width: "28px",
                    },
                  }),
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "btn-video-controls",
                  class: { "is-active": true },
                  staticStyle: {
                    background: "none",
                    border: "none",
                    "margin-left": "-10px",
                  },
                  attrs: { squared: "", id: "strike" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleStrike()
                    },
                  },
                },
                [
                  _c(
                    "b-tooltip",
                    {
                      attrs: {
                        target: "strike",
                        placement: "bottom",
                        triggers: "hover",
                      },
                    },
                    [_vm._v("Tachado")]
                  ),
                  _c("img", {
                    attrs: {
                      src: require("@/assets/icons/format/strike.svg"),
                      height: "28px",
                      width: "28px",
                    },
                  }),
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "btn-video-controls",
                  class: { "is-active": true },
                  staticStyle: {
                    background: "none",
                    border: "none",
                    "margin-left": "-8px",
                  },
                  attrs: { squared: "", id: "highlight" },
                  on: {
                    click: function ($event) {
                      return _vm.OnHighlight()
                    },
                  },
                },
                [
                  _c(
                    "b-tooltip",
                    {
                      attrs: {
                        target: "highlight",
                        placement: "bottom",
                        triggers: "hover",
                      },
                    },
                    [_vm._v("Realçar")]
                  ),
                  _c("img", {
                    attrs: {
                      src: require("@/assets/icons/format/highlight.svg"),
                      height: "28px",
                      width: "28px",
                    },
                  }),
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "btn-video-controls",
                  class: { "is-active": true },
                  staticStyle: { background: "none", border: "none" },
                  attrs: { squared: "", id: "bulletList" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleBulletList()
                    },
                  },
                },
                [
                  _c(
                    "b-tooltip",
                    {
                      attrs: {
                        target: "bulletList",
                        placement: "bottom",
                        triggers: "hover",
                      },
                    },
                    [_vm._v("Marcadores")]
                  ),
                  _c("img", {
                    attrs: {
                      src: require("@/assets/icons/format/orderedList.svg"),
                      height: "28px",
                      width: "28px",
                    },
                  }),
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "btn-video-controls",
                  class: { "is-active": true },
                  staticStyle: { background: "none", border: "none" },
                  attrs: { squared: "", id: "orderedList" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleOrderedList()
                    },
                  },
                },
                [
                  _c(
                    "b-tooltip",
                    {
                      attrs: {
                        target: "orderedList",
                        placement: "bottom",
                        triggers: "hover",
                      },
                    },
                    [_vm._v("Ordenação")]
                  ),
                  _c("img", {
                    attrs: {
                      src: require("@/assets/icons/format/bullet_list.svg"),
                      height: "28px",
                      width: "28px",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }