var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "format-container" }, [
      _c(
        "div",
        { staticClass: "file-container" },
        [
          _c(
            "b-nav-item-dropdown",
            {
              staticClass: "nav-dropdown",
              staticStyle: { color: "red" },
              attrs: { offset: "25", text: "Formatar", "no-caret": "" },
            },
            [
              _c(
                "b-dropdown-item",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.bold()
                    },
                  },
                },
                [
                  _c("p", { staticClass: "dropdown-item" }, [
                    _c("img", {
                      staticStyle: { "margin-right": "17px" },
                      attrs: {
                        src: require("@/assets/icons/format/bold_shortcut.svg"),
                      },
                    }),
                    _vm._v("Negrito"),
                    _c("span", { staticStyle: { "margin-left": "267px" } }, [
                      _vm._v("Ctrl + B"),
                    ]),
                  ]),
                ]
              ),
              _c(
                "b-dropdown-item",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.italic()
                    },
                  },
                },
                [
                  _c("p", { staticClass: "dropdown-item" }, [
                    _c("img", {
                      staticStyle: {
                        "margin-right": "12px",
                        width: "15px",
                        height: "15px",
                      },
                      attrs: {
                        src: require("@/assets/icons/format/italic_shortcut.svg"),
                      },
                    }),
                    _vm._v("Itálico"),
                    _c("span", { staticStyle: { "margin-left": "275px" } }, [
                      _vm._v("Ctrl + I"),
                    ]),
                  ]),
                ]
              ),
              _c(
                "b-dropdown-item",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.underline()
                    },
                  },
                },
                [
                  _c("p", { staticClass: "dropdown-item" }, [
                    _c("img", {
                      staticStyle: { "margin-right": "15px", width: "12px" },
                      attrs: {
                        src: require("@/assets/icons/format/underline.svg"),
                      },
                    }),
                    _vm._v("Sublinhado"),
                    _c("span", { staticStyle: { "margin-left": "238px" } }, [
                      _vm._v("Ctrl + U"),
                    ]),
                  ]),
                ]
              ),
              _c(
                "b-dropdown-item",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.fontFamily()
                    },
                  },
                },
                [
                  _c("p", { staticClass: "dropdown-item" }, [
                    _c("img", {
                      staticStyle: { "margin-right": "15px" },
                      attrs: {
                        src: require("@/assets/icons/format/font_family_shortcut.svg"),
                      },
                    }),
                    _vm._v("Fonte"),
                    _c("span", { staticStyle: { "margin-left": "279px" } }, [
                      _vm._v("Ctrl + Alt + F"),
                    ]),
                  ]),
                ]
              ),
              _c(
                "b-dropdown-item",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.fontColor()
                    },
                  },
                },
                [
                  _c("p", { staticClass: "dropdown-item" }, [
                    _c("img", {
                      staticStyle: { "margin-right": "15px" },
                      attrs: {
                        src: require("@/assets/icons/format/font_color.svg"),
                      },
                    }),
                    _vm._v("Cor da fonte"),
                  ]),
                ]
              ),
              _c("b-dropdown-divider"),
              _c(
                "b-dropdown-item",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.upercase()
                    },
                  },
                },
                [
                  _c("p", { staticClass: "dropdown-item" }, [
                    _vm._v("MAIÚSCULAS"),
                  ]),
                ]
              ),
              _c(
                "b-dropdown-item",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.lowercase()
                    },
                  },
                },
                [
                  _c("p", { staticClass: "dropdown-item" }, [
                    _vm._v("minúculas"),
                  ]),
                ]
              ),
              _c(
                "b-dropdown-item",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.startLowercase()
                    },
                  },
                },
                [
                  _c("p", { staticClass: "dropdown-item" }, [
                    _vm._v("Palavras Iniciando Com Maiúsculas"),
                  ]),
                ]
              ),
              _c(
                "b-dropdown-item",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleBox()
                    },
                  },
                },
                [
                  _c("p", { staticClass: "dropdown-item" }, [
                    _vm._v("Alternar caixa"),
                    _c("span", { staticStyle: { "margin-left": "248px" } }, [
                      _vm._v("Shift + F3"),
                    ]),
                  ]),
                ]
              ),
              _c("b-dropdown-divider"),
              _c(
                "b-dropdown-item",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.startUppercase()
                    },
                  },
                },
                [
                  _c("p", { staticClass: "dropdown-item" }, [
                    _vm._v("Frase iniciando com maiúscula"),
                  ]),
                ]
              ),
              _c(
                "b-dropdown-item",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.invertBox()
                    },
                  },
                },
                [
                  _c("p", { staticClass: "dropdown-item mb-2" }, [
                    _vm._v("iNVERTER cAIXA"),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }