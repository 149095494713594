var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            id: "modal-prevent-closing",
            "cancel-title": "Cancelar",
            "cancel-variant": "transparent",
            title: "Selecionar Fontes",
          },
          on: { hidden: _vm.resetModal },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-form",
            { attrs: { inline: "" } },
            [
              _c("p", { staticClass: "fontes" }, [
                _vm._v("Fontes disponíveis"),
              ]),
              _c("p", { staticClass: "fonteSize" }, [_vm._v("Tamanho ")]),
              _c("b-form-select", {
                staticStyle: {
                  "margin-top": "-15px",
                  "font-size": "17px",
                  height: "30px",
                  "text-align": "center",
                  "margin-left": "16px",
                  width: "82px !important",
                  "border-radius": "2px",
                },
                attrs: { options: _vm.options },
                model: {
                  value: _vm.selected,
                  callback: function ($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected",
                },
              }),
              _c(
                "b-input-group",
                { staticClass: "mb-0", attrs: { size: "sm" } },
                [
                  _c(
                    "b-input-group-prepend",
                    { attrs: { "is-text": "" } },
                    [_c("b-icon", { attrs: { icon: "search" } })],
                    1
                  ),
                  _c("b-form-input", {
                    staticClass: "mb-2 mr-sm-2 mb-sm-0 input-search",
                    attrs: {
                      id: "inline-form-input-name",
                      placeholder: "Buscar...",
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-list-group",
            {
              staticClass: "list-group",
              staticStyle: { "margin-top": "10px" },
            },
            _vm._l(_vm.filteredItems, function (item, key) {
              return _c(
                "b-list-group-item",
                {
                  key: key,
                  attrs: { button: "" },
                  on: {
                    click: function ($event) {
                      return _vm.onClickFontItem(item)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.name) + " ")]
              )
            }),
            1
          ),
          _c("b-form-textarea", {
            ref: "fontTextArea",
            staticClass: "textarea",
            staticStyle: {
              "border-radius": "1px",
              border: "1px",
              "box-shadow": "0 0 10px #719ECE",
            },
            attrs: { id: "textarea-no-resize", rows: "3", "no-resize": "" },
            model: {
              value: _vm.selectedFontName,
              callback: function ($$v) {
                _vm.selectedFontName = $$v
              },
              expression: "selectedFontName",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }